import * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import FullPageSlider from "../components/FullPageSlider/FullPageSlider"
import FullPageSliderWrapper from "../components/FullPageSlider/FullPageSliderWrapper"
import FullPageSliderContent from "../components/FullPageSlider/FullPageSliderContent"
import { Helmet } from 'react-helmet'

const NotFoundPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Nie ma takiej strony!</title>
        <meta name="description" content="Spędź z nami swój wypoczynek! Okolica pełna ✔ górskich szczytów ✔ wyciągów narciarskich ✔ wielu innych atrakcji!" />
      </Helmet>
      <FullPageSliderWrapper>
        <FullPageSlider images={data.homeBackgrounds.nodes}></FullPageSlider>
        <FullPageSliderContent>
          <h1>Nie ma takiej strony!</h1>
        </FullPageSliderContent>
      </FullPageSliderWrapper>
    </Layout>
  )
}

export const query = graphql`
query {
  homeBackgrounds: allFile (filter: {sourceInstanceName: {eq: "homeBackgrounds"}}, sort:{fields: [name], order:ASC}){
      nodes {
      childImageSharp {
          gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
          )
      }
      }
    }
  }
`

export default NotFoundPage
